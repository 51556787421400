import { Helmet } from "react-helmet";
import * as React from "react"

const title = 'Loris Pinna, développeur et designer freelance';
export const SITE_URL = "https://lorispinna.com";

export function buildSeo(data) {
    if(data == null) {
        return (
            <Helmet>
                <title>{title}</title>
            </Helmet>
        );
    }
    else {
        let finalTitle = `${data.title} ${!data.noTitle  ? `| ${title}` : ``}`;

        return (
            <Helmet>
                <html lang="fr" />
                <meta charSet="utf-8" />

                <title>{finalTitle}</title>
                <meta property="og:title" content={data.metaTitle || data.title }></meta>
                <meta property="meta:title" content={data.metaTitle || data.title }></meta> 
                
                <meta name="description" content={ data.metaDescription || data.description }></meta>
                <meta property="og:description" content={data.metaDescription || data.description }></meta>
                <meta property="meta:description" content={data.metaDescription || data.description }></meta>

                <meta property="og:image" content={ data.image || `${SITE_URL}/images/og-image.png` }></meta>
                <meta property="og:image:url" content={ data.image || `${SITE_URL}/images/og-image.png` }></meta>
                <meta property="twitter:image" content={ data.image || `${SITE_URL}/images/og-image.png` }></meta>

                <meta name="og:site_name" content={title}></meta>
                <meta name="theme-color" content="#2E1FFF"></meta>
                <meta name="og:url" content="https://lorispinna.com/"></meta>

                <meta name="twitter:card" content="summary"></meta>
            </Helmet>
        )
    }
}
